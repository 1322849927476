gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function () {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const path = entry.target;
          const length = path.getTotalLength();
          const svgParentClassList = path.closest('svg').classList;

          // クラス名に応じてfillの設定
          let fill = '#fff'; // デフォルトのfill色
          if (svgParentClassList.contains('is_strokeAnimation1')) {
            fill = 'url(#oneTeam_d)';
          } else if (svgParentClassList.contains('is_strokeAnimation3')) {
            fill = 'url(#value_d)';
          }

          if (window.innerWidth > 1200) {
            // PCの場合のストロークアニメーションの初期設定
            gsap.set(path, {
              strokeDasharray: length,
              strokeDashoffset: length,
              fillOpacity: 0,
            });
            // PCの場合のストロークアニメーション
            gsap.to(path, 1.5, {
              strokeDashoffset: 0,
              ease: 'power1.out',
              onComplete: function () {
                // ストロークアニメーション完了後のfillアニメーション
                gsap.to(path, 1, {
                  fillOpacity: 1,
                  fill: fill,
                  ease: 'power1.inOut',
                });
              },
            });
          } else {
            // SPの場合のフェードインアニメーション
            // fill-opacityを0から1へ変更する
            gsap.to(path, 1, {
              opacity: 1,
              fill: fill,
              fillOpacity: 1,
              ease: 'power1.inOut',
            });
          }

          // 監視を解除
          observer.unobserve(path);
        }
      });
    },
    {
      rootMargin: '0px',
      threshold: 0.1,
    }
  );

  // 監視対象の追加
  document.querySelectorAll('.strokeAnimation path').forEach((path) => {
    observer.observe(path);
  });
});

document.addEventListener('DOMContentLoaded', function () {
  const isMobile = window.innerWidth <= 1199; // 768px以下をスマートフォンとする

  const mvImg01Animation = () => {
    const selector = '.mvImg01';
    const speed = isMobile ? 0.03 : 0.05; // スクロール速度（SPでは0.03、PCでは0.05）
    const transitionTime = isMobile ? 0.6 : 1.2; // トランジション時間（秒）（SPでは0.6秒、PCでは1.2秒）

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const mvImg02Animation = () => {
    const selector = '.mvImg02';
    const speed = isMobile ? -0.01 : 0.04;
    const transitionTime = isMobile ? 0.6 : 0.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const mvImg03Animation = () => {
    const selector = '.mvImg03';
    const speed = isMobile ? 0.01 : 0.02;
    const transitionTime = isMobile ? 0.6 : 1.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const missionImg01Animation = () => {
    const selector = '.missionImg01';
    const speed = isMobile ? -0.01 : 0.01;
    const transitionTime = isMobile ? 0.2 : 1.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const valueImg01Animation = () => {
    const selector = '.valueImg01';
    const speed = isMobile ? -0.01 : -0.03;
    const transitionTime = isMobile ? 0.2 : 1.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const messageImg01Animation = () => {
    const selector = '.messageImg01';
    const speed = isMobile ? 0.01 : 0.02;
    const transitionTime = isMobile ? 1.2 : 1.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const messageImg02Animation = () => {
    const selector = '.messageImg02';
    const speed = isMobile ? 0.01 : 0.02;
    const transitionTime = isMobile ? 1.2 : 1.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const messageImg03Animation = () => {
    const selector = '.messageImg03';
    const speed = isMobile ? -0.01 : -0.015;
    const transitionTime = isMobile ? 1.2 : 1.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const visionImg01Animation = () => {
    const selector = '.visionImg01';
    const speed = isMobile ? -0.01 : -0.015;
    const transitionTime = isMobile ? 1.2 : 1.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const kyotoMapAnimation = () => {
    const selector = '.kyotoMap';
    const speed = isMobile ? -0.01 : -0.015;
    const transitionTime = isMobile ? 1.2 : 1.2;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const kyotoImgAnimation = () => {
    const selector = '.kyotoImg';
    const speed = isMobile ? -0.01 : 0.008;
    const transitionTime = isMobile ? 1.2 : 0.8;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const interviewImg01Animation = () => {
    const selector = '.interviewImg01';
    const speed = isMobile ? -0.01 : 0.008;
    const transitionTime = isMobile ? 1.2 : 0.8;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  const interviewImg02Animation = () => {
    const selector = '.interviewImg02';
    const speed = isMobile ? -0.01 : 0.008;
    const transitionTime = isMobile ? 1.2 : 0.8;

    const element = document.querySelector(selector);
    gsap.set(element, {
      willChange: 'transform',
      transition: `transform ${transitionTime}s cubic-bezier(0, 0, 0, 1) 0s`,
    });
    gsap.to(element, {
      y: () => `+=${ScrollTrigger.maxScroll(window) * speed}`,
      ease: 'none',
      scrollTrigger: {
        trigger: element,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    });
  };

  mvImg01Animation();
  mvImg02Animation();
  mvImg03Animation();
  missionImg01Animation();
  valueImg01Animation();
  messageImg01Animation();
  messageImg02Animation();
  messageImg03Animation();
  visionImg01Animation();
  kyotoMapAnimation();
  kyotoImgAnimation();
  interviewImg01Animation();
  interviewImg02Animation();
});

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function () {
  const fadeInBtmToElements01 = document.querySelectorAll('.fade-in-btm-to01');
  fadeInBtmToElements01.forEach((element) => {
    gsap.to(fadeInBtmToElements01, {
      opacity: 1,
      y: 0,
      duration: 2,
      stagger: 0.3,
      scrollTrigger: {
        trigger: fadeInBtmToElements01[0],
        start: 'bottom 95%',
        toggleActions: 'play none none none',
      },
    });
  });

  const fadeInBtmToElements02 = document.querySelectorAll('.fade-in-btm-to02');
  const staggerValue = window.innerWidth >= 768 ? 0.3 : 0.1;
  fadeInBtmToElements02.forEach((element) => {
    gsap.to(fadeInBtmToElements02, {
      opacity: 1,
      y: 0,
      duration: 2,
      stagger: staggerValue,
      scrollTrigger: {
        trigger: fadeInBtmToElements02[0],
        start: 'bottom 95%',
        toggleActions: 'play none none none',
      },
    });
  });

  const fadeInBtmToElements03 = document.querySelectorAll('.fade-in-btm-to03');
  fadeInBtmToElements03.forEach((element) => {
    gsap.to(fadeInBtmToElements03, {
      opacity: 1,
      y: 0,
      duration: 2,
      stagger: 0.3,
      scrollTrigger: {
        trigger: fadeInBtmToElements03[0],
        start: 'bottom 95%',
        toggleActions: 'play none none none',
      },
    });
  });

  const fadeInBtmToElements04 = document.querySelectorAll('.fade-in-btm-to04');
  fadeInBtmToElements04.forEach((element) => {
    gsap.to(fadeInBtmToElements04, {
      opacity: 1,
      y: 0,
      duration: 2,
      stagger: 0.3,
      scrollTrigger: {
        trigger: fadeInBtmToElements04[0],
        start: 'bottom 95%',
        toggleActions: 'play none none none',
      },
    });
  });

  const fadeInBtmToElements05 = document.querySelectorAll('.fade-in-btm-to05');
  fadeInBtmToElements05.forEach((element) => {
    gsap.to(fadeInBtmToElements05, {
      opacity: 1,
      y: 0,
      duration: 2,
      stagger: 0.3,
      scrollTrigger: {
        trigger: fadeInBtmToElements05[0],
        start: 'bottom 95%',
        toggleActions: 'play none none none',
      },
    });
  });

  gsap.to('.mask img', {
    y: () => {
      if (window.innerWidth >= 768) {
        // 768px以上ならPC用の値
        return (ScrollTrigger.maxScroll(window) * 0.02) / 2;
      } else {
        // 768px未満ならSP用の値
        return (ScrollTrigger.maxScroll(window) * -0.01) / 2; // SP用に適した値を設定
      }
    },
    ease: 'power1.inOut',
    duration: 3,
    scrollTrigger: {
      trigger: '.mask img',
      start: 'top bottom', // スクロール開始位置
      end: 'bottom top', // スクロール終了位置
      // scrub: true,
      scrub: 1.5,
    },
  });

  // gsap.to('.un_lp_bl03 .bg01', {
  //   y: () => {
  //     if (window.innerWidth >= 768) {
  //       // 768px以上ならPC用の値
  //       return (ScrollTrigger.maxScroll(window) * 0.01) / 3;
  //     } else {
  //       // 768px未満ならSP用の値
  //       return (ScrollTrigger.maxScroll(window) * -0.01) / 2; // SP用に適した値を設定
  //     }
  //   },
  //   ease: 'none',
  //   duration: 3,
  //   scrollTrigger: {
  //     trigger: '.un_lp_bl03 .bg01',
  //     start: 'top bottom', // スクロール開始位置
  //     end: 'bottom top', // スクロール終了位置
  //     // scrub: true,
  //     scrub: 1.5,
  //   },
  // });
  const isPC = window.innerWidth >= 768; // 768px以上はPC、それ以下はSPと判断
  gsap.fromTo(
    '.un_lp_bl03 .bg01', // アニメーションさせる要素
    {
      yPercent: isPC ? 5 : -2,
    },
    {
      yPercent: isPC ? -5 : 2,
      ease: 'none', // イージングなし
      scrollTrigger: {
        trigger: '.un_lp_bl03 .bg01', // アニメーションのトリガー要素
        start: 'top bottom', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        scrub: 2, // 動作が遅くなる
      },
    }
  );

  gsap.fromTo(
    '.un_lp_bl03 .bg02', // アニメーションさせる要素
    {
      yPercent: isPC ? 5 : -2,
    },
    {
      yPercent: isPC ? -5 : 2,
      ease: 'none', // イージングなし
      scrollTrigger: {
        trigger: '.un_lp_bl03 .bg02', // アニメーションのトリガー要素
        start: 'top bottom', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        scrub: 2, // 動作が遅くなる
      },
    }
  );
});

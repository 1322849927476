'use strict';

document.addEventListener('DOMContentLoaded', function () {
  const accordions = document.querySelectorAll('.js_accordion');

  function toggleAccordion(event) {
    if (window.innerWidth <= 768) {
      const dt = event.currentTarget;
      const dd = dt.nextElementSibling;

      if (dd.style.display === 'flex') {
        dd.style.display = 'none';
        dt.classList.remove('is_open');
      } else {
        // 他のアコーディオンは閉じずに開く
        dd.style.display = 'flex';
        dt.classList.add('is_open');
      }
    }
  }

  accordions.forEach((accordion) => {
    const dt = accordion.querySelector('dt');
    const dd = accordion.querySelector('dd');

    dt.addEventListener('click', toggleAccordion);

    // PC（768px以上）の場合は常に展開状態
    function updateDisplay() {
      if (window.innerWidth > 768) {
        dd.style.display = 'flex';
        dt.classList.remove('is_open'); // リサイズ時に is_open を外す
      } else {
        dd.style.display = 'none';
        dt.classList.remove('is_open');
      }
    }

    updateDisplay();
    window.addEventListener('resize', updateDisplay);
  });
});

'use strict';

document.addEventListener('DOMContentLoaded', function () {
  // document
  //   .getElementById('closeMenuButton')
  //   .addEventListener('click', closeMenu);
  // ---------------------------
  // ハンバーガーメニュー
  const menu = document.querySelector('#menuBtn');
  const nav = document.querySelector('#nav');
  const body = document.body;
  menu.addEventListener('click', () => {
    body.classList.toggle('is_hidden');
    menu.classList.toggle('is_open');
    nav.classList.toggle('is_open');
  });

  // function closeMenu() {
  //   if (body.classList.contains('is_hidden')) {
  //     body.classList.toggle('is_hidden');
  //     menu.classList.toggle('is_open');
  //     nav.classList.toggle('is_open');
  //   }
  // }

  // ---------------------------
  // openingアニメーション
  const openingBg = document.querySelector('.bl_opening_bg');
  const openingInner = document.querySelector('.bl_opening_inner');
  const opening = document.querySelector('.bl_opening');
  const lead = document.querySelector('.bl_opening_inner .lead');
  // アニメーションを開始
  openingBg.style.animationPlayState = 'running';
  openingInner.style.animationPlayState = 'running';
  if (!sessionStorage.getItem('hasVisited')) {
    document.body.classList.add('is_hidden');
    lead.addEventListener('animationend', function () {
      // .leadのアニメーション終了後、.bl_opening_innerの要素をフェードアウト
      openingInner.classList.add('fadeOutInner');
      // .bl_opening_innerのフェードアウトが終わったことを検出
      openingInner.addEventListener(
        'animationend',
        function () {
          // .bl_opening_innerのフェードアウト終了から1秒後に全体をフェードアウト
          setTimeout(function () {
            opening.classList.add('fadeOut');
            opening.addEventListener(
              'animationend',
              function () {
                // フェードアウト完了後の処理
                opening.style.pointerEvents = 'none';
                document.body.classList.remove('is_hidden');
              },
              { once: true }
            );
          }, 1000); // .bl_opening_innerフェードアウト後の遅延時間
        },
        { once: true }
      );
    });
    sessionStorage.setItem('hasVisited', 'true');
  } else {
    // 2回目以降の訪問ではオープニングアニメーションをスキップ
    opening.style.display = 'none';
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const fadeIns = document.querySelectorAll('.is_fadeIn');
  const fadeInDowns = document.querySelectorAll('.is_fadeInDown');

  function checkPosition1() {
    for (let i = 0; i < fadeIns.length; i++) {
      const fadeIn = fadeIns[i];
      const position = fadeIn.getBoundingClientRect();

      // 要素がビューポート内にあるかチェック
      if (position.top < window.innerHeight && position.bottom >= 0) {
        fadeIn.classList.add('start');
      }
    }
  }

  function checkPosition2() {
    for (let i = 0; i < fadeInDowns.length; i++) {
      const fadeInDown = fadeInDowns[i];
      const position = fadeInDown.getBoundingClientRect();

      // 要素がビューポート内にあるかチェック
      if (position.top < window.innerHeight && position.bottom >= 0) {
        fadeInDown.classList.add('start');
      }
    }
  }

  // スクロール時とページ読み込み時に位置をチェック
  window.addEventListener('scroll', checkPosition1);
  window.addEventListener('resize', checkPosition1); // ウィンドウサイズが変更されたときもチェック
  window.addEventListener('scroll', checkPosition2);
  window.addEventListener('resize', checkPosition2); // ウィンドウサイズが変更されたときもチェック
  checkPosition1();
  checkPosition2();
});

// document.addEventListener('DOMContentLoaded', function () {
//   const observer = new IntersectionObserver(
//     (entries, observer) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           const path = entry.target;
//           const length = path.getTotalLength();
//           const svgParentClassList = path.closest('svg').classList;

//           path.style.strokeDasharray = length;
//           path.style.strokeDashoffset = length;

//           // クラス名に応じてfillの設定
//           if (svgParentClassList.contains('is_strokeAnimation1')) {
//             path.style.fill = 'url(#oneTeam_d)';
//           } else if (svgParentClassList.contains('is_strokeAnimation2')) {
//             path.style.fill = '#fff';
//           } else if (svgParentClassList.contains('is_strokeAnimation3')) {
//             path.style.fill = 'url(#philosophy_d)';
//           } else if (svgParentClassList.contains('is_strokeAnimation4')) {
//             path.style.fill = '#fff';
//           }
//           // 他のクラスに基づく条件分岐も同様に

//           path.style.fillOpacity = 0;

//           path.style.animation = `
//                       draw 1.5s linear forwards,
//                       fadeOutStroke 1s linear forwards 1.5s,
//                       fadeInFill 1s linear forwards 1.5s`;

//           // 監視を解除
//           observer.unobserve(path);
//         }
//       });
//     },
//     {
//       rootMargin: '0px',
//       threshold: 0.1, // 10%が表示された時点でトリガー
//     }
//   );

//   // 監視対象の追加
//   document.querySelectorAll('.strokeAnimation path').forEach((path) => {
//     observer.observe(path);
//   });
// });

document.addEventListener('DOMContentLoaded', () => {
  const observer2 = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const letterGroups = entry.target.querySelectorAll('.letter-group');
          letterGroups.forEach((group, index) => {
            group.style.animation = `fadeIn 1s ease forwards ${index * 0.017}s`;
          });
          // 一度アニメーションを実行したら、それ以上は監視しない
          observer2.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 0.5, // 50%の要素が見えた時点でトリガー
    }
  );

  // 監視対象の要素を指定
  const elements = document.querySelectorAll('.un_home_mv .hdg');
  elements.forEach((el) => observer2.observe(el));
});
